
import { React, useState, useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import api from '../../api.js';
import { toast } from 'react-toastify';

//const colors = ['#0088FE', '#00C49F', '#FFBB28', '#792f0a', 'red', 'pink'];
const colors = ['#4d0606', '#000', '#857553', '#5f2a10', 'red', 'pink'];

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
  Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

export default function ChartFaturamento(ano) {

  const [faturamento, setFaturamento] = useState([]);
  const [total, setTotal] = useState(0);
  const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

  useEffect(() => {
    (async () => {
        try {
            if (!ano.ano) { return; }
            const { data: pagar } = await api.get('/faturamentoMensal/' + ano.ano, {
              headers: {
                'Authorization': `Bearer ${tokenSession}`,
            }
            });
            if (pagar && pagar.length > 0) {
                const formattedData = pagar.map((item) => ({
                    ...item,
                    uv: parseFloat(item.uv)
                }));
                setFaturamento(formattedData);
                // Totalizando os valores
                const total = formattedData.reduce((acc, item) => acc + item.uv, 0);
                setTotal(total); // Definindo o total no estado
            } else {
                setTotal(0);
                setFaturamento([{ name: '0', label: '0', uv: 1 }]);
            }
        } catch (error) {
            toast.error(error.message || 'Erro ao carregar dados');
        }
    })();
}, [ano]);

  return (
    <>
      <div>
        <h3 style={{ display: 'flex', justifyContent: 'center' }}>Total faturamento: R$ {total}</h3>
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={550}
          height={300}
          data={faturamento}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          {/* <YAxis /> */}
          <Bar dataKey="uv" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
            {faturamento.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % 50]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

