import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import api from '../../api.js';
import './ContasPagar.css';
import { toast } from 'react-toastify';
import Avatar from '@mui/material/Avatar';
import ModalConfirmacao from '../Modal/Modal-Confirmacao.jsx';
import ModalPagar from '../Modal/Modal-pagar.jsx';

const ContasPagar = () => {

    const date = new Date();
    const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const apiRef = useGridApiRef();

    const [primeiroDiaString, setPrimeiroDiaString] = useState(`${primeiroDia.getFullYear()}-${(primeiroDia.getMonth() + 1).toString().length < 2 ? `0${primeiroDia.getMonth() + 1}` : primeiroDia.getMonth() + 1}-${primeiroDia.getDate().toString().length < 2 ? `0${primeiroDia.getDate()}` : primeiroDia.getDate()}`);
    const [lastDayDate, setLastDayDate] = useState(`${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().length < 2 ? `0${lastDay.getMonth() + 1}` : lastDay.getMonth() + 1}-${lastDay.getDate().toString().length < 2 ? `0${lastDay.getDate()}` : lastDay.getDate()}`);

    const [openModal, setOpenModal] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [titulo, setTitulo] = useState("");
    const [operacao, setOperacao] = useState("");
    const [selectedId, setSelectedId] = useState(null);
    const [contas, setContas] = useState([]);
    const [status, setStatus] = useState('');
    const [emitentes, setEmitentes] = useState([]);
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));
    const acesso = localStorage.getItem('sessionChaveAcesso');
    const acessoContador = localStorage.getItem("sessionContador");
    const [selectedEmitente, setSelectedEmitente] = useState("");
    const filtroStatus = ['Todas', 'A Pagar', 'Pagas'];
    const [total, setTotal] = useState(0);
    const [filtro, setFiltro] = useState("0");
    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);

    // Select emitentes , aqui vai usar no modal tbm
    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/emitentesAll/" + acessoContador + "/" + acesso);
                if (data) {
                    setEmitentes(data);
                    if (data.length > 0) {
                        setSelectedEmitente(data[0].id);
                    }
                }
            } catch (error) {
                alert(error);
            }
        })();
    }, []);

    // buscar as contas a pagar
    useEffect(() => {
        (async () => {
            handleFiltro();
        })();
    }, [selectedEmitente, filtro, lastDayDate, primeiroDiaString]);

    const columns = [
        {
            field: 'editar',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                params.row.status !== "A Pagar" ? (
                    <button style={{ opacity: 0.3 }}>
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                ) : (
                    <button onClick={() => handleEditarPagar(params.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                )
            )
        },
        { field: 'nome', headerName: 'Credor', flex: 0.7, maxWidth: 300 },
        { field: 'descricao', headerName: 'Descrição', flex: 0.7, maxWidth: 300 },
        {
            field: 'lancamento',
            headerName: 'Lançamento',
            align: 'center',
            headerAlign: 'center',
            maxWidth: 120,
            flex: 0.5,
            type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        {
            field: 'vencimento',
            headerName: 'Vencimento',
            align: 'center',
            headerAlign: 'center',
            maxWidth: 120,
            flex: 0.5,
            type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        {
            field: 'valor', headerName: 'Valor', flex: 0.5, type: 'number',
            align: 'center',
            headerAlign: 'center',
            maxWidth: 150,
            valueFormatter: (value) => {
                return new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                }).format(value);
            }
        },
        { field: 'status', headerName: 'Status', width: 80 },
        {
            field: "excluir",
            headerName: '',
            width: 85,
            renderCell: (params) => (
                params.row.status === "A Pagar" ? (
                    <Avatar src={'https://img.icons8.com/plasticine/100/filled-trash.png'} alt=''
                        className='btn-ativa-pagar'
                        onClick={() => handleOpenModalConfirmacao(params.id, params.row.status, 'Excluir', 'Confirma a exclusão ?')}
                        title='Excluir'
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/plasticine/100/filled-trash.png'} alt=''
                        className='btn-ativa-pagar'
                        style={{ opacity: 0.3, cursor: 'not-allowed' }}
                        title='Não é possível excluir conta ja baixada'
                    />
                )
            )
        }

    ];

    function getRowClassName(params) {
        const status = params.row.status; // Suponha que a coluna "status" contenha o status da linha
        if (status === 'A Pagar') {
            return 'linha-aberto'; // Classe CSS para linhas aprovadas
        } else if (status === 'Paga') {
            return 'linha-recebido'; // Classe CSS para linhas pendentes
        } else {
            return ''; // Classe padrão para outras linhas
        }
    }

    // para pegar os dados da linha inteira digitada
    const handleRowSelection = (selectionModel, api) => {
        const selectedRowsData = selectionModel.map((id) => api.current.getRow(id));
        setSelectedRows(selectedRowsData); // Armazena os dados completos das linhas selecionadas
    };

    const handleOpenModalConfirmacao = (id, st, op, t) => {
        setSelectedId(id);
        setOperacao(op);
        setTitulo(t);
        setOpenModalConfirmacao(true);
    }

    const handleCloseModalConfirmacao = () => {
        setOpenModalConfirmacao(false);
        setOperacao("");
    }

    const handleEditarPagar = (id) => {
        try {
            setSelectedId(id);
            setOperacao("Editar");
            setOpenModal(true);
        } catch (error) {
            toast.error(error);
        }
    }

    function handlePrimeiraData(e) {
        const resultArray = e.substring(0, 10) + ' 00:00:00';
        setPrimeiroDiaString(resultArray);
    }

    function handleUltimaData(e) {
        const resultArray = e.substring(0, 10) + ' 00:00:00';
        setLastDayDate(resultArray);
    }

    const handleFiltro = async (e) => {
        let status = "Todas";
        if (filtro === "1") {
            status = "A Pagar"
        }
        if (filtro === "2") {
            status = "Paga"
        }
        try {
            if (!selectedEmitente) { return; }
            const { data: pagar } = await api.get("/contasPagar/" + selectedEmitente + "/" + status + "/" + primeiroDiaString + "/" + lastDayDate);
            if (pagar.data) {
                setContas(pagar.data);
                let iptot = 0;
                pagar.data.map((fat) => {
                    iptot = iptot + parseFloat(fat.valor);
                    return iptot;
                });
                let numeroComDecimais = iptot.toFixed(2);
                let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                setTotal(numeroFormatadoComDecimais);
                setSelectedId(null);
            } else {
                setContas([]);
            }
        } catch (error) {
            setContas([]);
            if (error.response && error.response.status === 404) {
                const errorMessage = error.response.data.message;
                toast.warning('Cod: 404 - ' + errorMessage); // se estiver usando uma biblioteca como react-toastify
            } else {
                // Outras ações para erros não previstos
                toast.error("Erro inesperado", error);
            }
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        handleFiltro();
    }

    const handleOpenModalBaixa = () => {
        if (selectedRows.length > 0) {
            setOperacao('Baixar');
            setTitulo('Confirma a baixa das contas selecionadas ?');
            setOpenModalConfirmacao(true);
        } else {
            toast.warning('Favor selecionar os registros que você quer emitir boleto.');
        }
    }

    const handleEmitente = (id) => {
        setSelectedEmitente(id);
    }

    function handleSetStatus(e) {
        setFiltro(e);
    }

    const handleExcluirPagar = async () => {
        try {
            const retorno = await api.delete('/contasPagar/exclui/' + selectedId, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            if (retorno.success) {
                toast.success(retorno);
            } else {
                toast.error(retorno)
            }
        } catch (error) {
            toast.error('Problema ao tentar excluir o registro : ' + error);
        }
        handleFiltro();
    }

    const handleBaixaPagar = () => {
        toast.success('Baixa efetuada com sucesso.');
        handleFiltro();
    }

    return (
        <div className='container-pagar'>
            <ModalPagar statusModal={openModal} onCloseModal={handleCloseModal} operacao={operacao} selectedId={selectedId} emitentes={emitentes}></ModalPagar>
            <ModalConfirmacao statusModal={openModalConfirmacao} onCloseModalConfirmacao={handleCloseModalConfirmacao} onExcluirPagar={handleExcluirPagar} onBaixaPagar={handleBaixaPagar} titulo={titulo} operacao={operacao} origem='Pagar' selectedRows={selectedRows}></ModalConfirmacao>
            <div className="container-top-pagar">
                <div className="column-top-pagar">
                    <h2 style={{ color: '#fff' }} >Contas a Pagar</h2>
                </div>
                <div className="btn-novo-fat">
                    <button onClick={() => {
                        setOpenModal(true)
                        setOperacao('Incluir')
                    }
                    }>Nova</button>
                </div>
                <button className='btn-baixar-pagar' onClick={handleOpenModalBaixa} style={{ border: "none", width: 100 }} >Baixar Conta</button>
            </div>
            <div className="nf-filtro-pagar">
                <h2 style={{ padding: 10, color: '#fff' }}>Filtros: </h2>
                <TextField
                    id="datetime-inicio"
                    label="Inicial"
                    type="date"
                    defaultValue={primeiroDiaString}
                    onChange={(e) => handlePrimeiraData(e.target.value)}
                    variant="filled"
                    className="fat-custom-textfield"
                    InputLabelProps={{
                        shrink: true,
                        style: { color: 'white' }, // Cor do label
                    }}
                    InputProps={{
                        style: { color: 'white' }, // Cor do texto
                    }}
                />
                <span><h3 style={{ padding: 10, color: '#fff' }}>até</h3> </span>
                <TextField
                    id="datetime-final"
                    label="Final"
                    variant="filled"
                    type="date"
                    defaultValue={lastDayDate}
                    onChange={(e) => handleUltimaData(e.target.value)}
                    className="fat-custom-textfield"
                    InputLabelProps={{
                        shrink: true,
                        style: { color: 'white' }, // Cor do label
                    }}
                    InputProps={{
                        style: { color: 'white' }, // Cor do texto
                    }}
                />

                <select className='btn-filtrar-pagar' style={{ width: 200, border: 'none' }}
                    onChange={(e) => handleEmitente(e.target.value)}
                    required
                    value={selectedEmitente}
                >
                    <option value="">Selecione um emitente</option>
                    {
                        Array.isArray(emitentes) && emitentes.length > 0 ? (
                            emitentes.map((emit) => (
                                <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                            ))
                        ) : (
                            <option value="">Nenhum emitente disponível</option>
                        )
                    }
                </select>

                <select className='btn-filtrar-pagar'
                    onChange={(e) => handleSetStatus(e.target.value)}
                    required
                >
                    {
                        filtroStatus.map((number, index) => (
                            <option key={index} value={index}>{number}</option>
                        ))
                    }
                </select>
              
                <button className='btn-filtrar-pagar' onClick={handleFiltro} >Filtrar</button>

                <div className='div-total-pagar'>
                    <h2 style={{ color: '#fff' }}>Total do filtro: R$ {total}</h2>
                </div>
            </div>

            <div className="nf-table-fat">
                <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                    <DataGrid
                        autoHeight
                        columns={columns}
                        localeText={{
                            footerRowSelected: (count) =>
                                count !== 1 ? `${count.toLocaleString()} linhas selecionadas` : `${count.toLocaleString()} linha selecionada`,
                            footerTotalRows: 'Total de linhas:',
                            MuiTablePagination: {
                                labelRowsPerPage: 'Linhas por página:',
                            },
                        }}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rows={contas ?? []}
                        isRowSelectable={(params) => {
                            // Exemplo de condição: habilitar o checkbox se o valor de uma coluna "status" for diferente de 'Recebido'
                            return params.row.status !== 'Recebido';
                        }}
                        disableColumnResize={true}
                        apiRef={apiRef} // Passando o apiRef para o DataGrid
                        getRowClassName={getRowClassName}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        onRowSelectionModelChange={(selection) => {
                            handleRowSelection(selection, apiRef) // Passando a seleção e apiRef para pegar os dados      
                            // setSelectedRows(selection); // Armazena as linhas selecionadas no estado                        
                        }}
                    />
                </Box>
            </div>
        </div>
    );
};

export default ContasPagar;
