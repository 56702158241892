import { DataGrid } from '@mui/x-data-grid';
import './Emitente.css';
import { useEffect, useState, useSyncExternalStore } from 'react';
import Box from '@mui/material/Box';
import ModalEmitente from '../Modal/Modal-Emitente.jsx';
import api from '../../api.js';
import ModalDialog from '../Modal/Modal-Dialog.jsx';
import { styled } from '@mui/system';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Emitente() {

    const navigate = useNavigate();
    const [openModalNova, setOpenModalNova] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [emitente, setEmitente] = useState(null);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [atualiza, setAtualiza] = useState("");
    const [titulo, setTitulo] = useState("");
    const [operacao, setOperacao] = useState("");
    const [status, setStatus] = useState("");
    const [idFocus, setIdFocus] = useState(0);

    const acesso = localStorage.getItem('sessionChaveAcesso');
    const acessoContador = localStorage.getItem('sessionContador');
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    async function handleCloseModalNova() {
        setSelectedId(null);
        setOpenModalNova(false);
        fetchData();
    }

    function handleOpenModalNova() {
        if (acessoContador > 0){
            setOpenModalNova(true);
        } else {
            toast.warning('Rotina liberada apenas para o seu contador.');
            return;
        }
    }

    const RedCell = styled('div')({
        color: '#88240b',
        borderRadius: '15px',
        textAlign: 'Left'
    });

    const GreenCell = styled('div')({
        // backgroundColor: '#1f58af',
        color: '#0142f7',
        borderRadius: '15px',
        textAlign: 'Left'
    });

    const columns = [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'cnpj', headerName: 'CNPJ', width: 150, flex: 1, minWidth: 150 },
        { field: 'razao', headerName: 'Razão Social', width: 300, flex: 1, minWidth: 300 },
        { field: 'nome_cidade', headerName: 'Cidade', width: 150, flex: 1 },
        { field: 'nome_estado', headerName: 'Estado', width: 70, flex: 1 },
        {
            field: 'nfse', headerName: 'NFSe', width: 120,
            renderCell: (params) => {
                return params.row.nfse != "Habilitado" ? (
                    <RedCell>{params.row.nfse}</RedCell>
                ) : (
                    <GreenCell>{params.row.nfse}</GreenCell>
                );
            },
        },
        { field: 'id_focusnfe', headerName: 'ID NFSe', width: 95 },
        { field: 'chave_acesso', headerName: 'Chave Acesso', width: 100 },
        {
            field: 'editar',
            headerName: '',
            width: 90,
            renderCell: (params) => (
                <button className='btn-enviar' onClick={() => handleButtonEditar(params.id)}>
                    Editar
                </button>
            )
        },
        {
            field: 'habilitaNFSe',
            headerName: '',
            width: 110,
            renderCell: (params) => {
                // const isDisabled = params.row.nfse === 'Habilitado'; // Supondo que 'active' é o valor que habilita o botão
                return (
                    <button
                        className='btn-ativar'
                        onClick={() => handleOpenModalDialog(params.id, params.row.nfse)}
                    // disabled={isDisabled}
                    >
                        Habilita NFSe
                    </button>
                );
            }
        },
        {
            field: 'desativar',
            headerName: '',
            width: 100,
            renderCell: (params) => (
                <button className='btn_desativa_emit' onClick={() => handleButtonStatus(params.id, params.row.nfse, params.row.id_focusnfe)}>
                    Desativar
                </button>
            )
        },
        {
            field: 'excluir',
            headerName: '',
            width: 100,
            renderCell: (params) => (
                <button className='btn_excluir_emit' onClick={() => handleButtonExcluir(params.id, params.row.nfse)}>
                    Excluir
                </button>
            )
        }
    ];

    function handleButtonEditar(id) {
        setSelectedId(id);
        setOpenModalNova(true);
    }

    const handleButtonExcluir = async (id, status) => {
        if (status === "Habilitado") {
            toast.warning('Empresa já Habilitada não pode ser excluida');
        } else {
            // ver se não tem movimento para essa empresa
            const temFaturamento = await api.get('/emitente/faturamento/' + id);
            if (temFaturamento.data[0].total > 0) {
                toast.warning('Esse emitente possui faturamento gerado, não pode ser excluido');
            } else {
                const temCliente = await api.get('/emitente/cliente/' + id);
                if (temCliente.data[0].total > 0) {
                    toast.warning('Esse emitente possui clientes amarrado nele, não pode ser excluido');
                } else {
                    const nfse = await api.get('/emitente/nfse/' + id);
                    if (nfse.data[0].total > 0) {
                        toast.warning('Esse emitente possui NFSe gerada, não pode ser excluido');
                    } else {
                        setSelectedId(id);
                        setTitulo('Confirma a exclusão dessa empresa ?');
                        setOperacao('Exclusao');
                        setOpenModalDialog(true);
                    }
                }
            }
        }
    }

    const handleButtonStatus = async (id, status, id_focus) => {
        setSelectedId(id);
        setIdFocus(id_focus);
        if (status === 'Habilitado' || status === 'Pendente') {
            setTitulo('Confirma a desativação da empresa');
            setOperacao('Desativacao');
            setStatus('Desativar');
        } else {
            setTitulo('Confirma a ativação da empresa')
            setOperacao('Ativacao');
            setStatus('Ativar');
        }
        setOpenModalDialog(true);
    }

    function handleCloseModalDialog() {
        setOpenModalDialog(false);
    }

    function handleOpenModalDialog(id, status) {
        if (status === "Habilitado") {
            toast.warning('Empresa já Habilitada para emitir NFSe');
        } else {
            setSelectedId(id);
            setTitulo('Confirma a ativação dessa empresa para emissão de NFSe ?');
            setOperacao('Habilitar');
            setOpenModalDialog(true);
        }
    }

    const onMudaStatus = async () => {
        setTitulo("");
        setOperacao("");
        try {
            const retorno = await api.put('/emitente/status/' + selectedId + "/" + status + "/" + idFocus);
            toast.success(retorno.data);
            setAtualiza(Math.random());
        } catch (error) {
            toast.error('Problema na rotina....: ' + error);
        }
        setStatus("");
    }

    const onAtivarEmpresa = async () => {
        setOpenModalDialog(false);
        toast.warning('Estamos ativando a empresa para emissão de NFSe, aguarde');
        try {
            if (!selectedId) return;
            const response = await api.get("/emitente/" + selectedId);
            const emitente = response.data.emitente || response.data; // Ajuste conforme necessário

            if (!emitente) return;
            if (emitente[0]) {
                const obj = (emitente[0]);
                const { nome, razao, cnpj, cep, ibge, insc_municipal, ie, endereco, bairro, cidade, estado, numero, cod_tributario_municipio } = obj;
                const objEnvio =
                {
                    id_local: selectedId,
                    nome_fantasia: nome,
                    nome: razao,
                    cnpj: cnpj.replace(/[^0-9]/g, ''),
                    cep: cep,
                    ibge: ibge,
                    inscricao_municipal: insc_municipal,
                    inscricao_estadual: ie,
                    logradouro: endereco,
                    bairro: bairro,
                    numero: numero,
                    complemento: '',
                    telefone: '',
                    uf: estado,  // aqui tem q ser SP nao 35 por exemplo
                    municipio: cidade, // aqui tambem é o nome da cidade
                    cod_tributario_municipio: cod_tributario_municipio,
                    regime_tributario: 1,
                    enviar_email_destinatario: false,
                    email: '',
                    discrimina_imposto: true,
                    habilita_nfe: false,
                    habilita_nfce: false,
                    habilita_nfse: true
                }

                const response = await api.post('/empresaFocus', objEnvio);
                if (response) {
                    // pegar os token
                    const obj = {
                        token_homologacao: response.data.token_homologacao,
                        token_producao: response.data.token_producao,
                        id: selectedId
                    }
                    const upd = await api.put('/emitenteToken', obj);
                }
                setAtualiza(new Date());
            }
        } catch (error) {
            toast.error('Erro ao tentar ativara empresa:', error);
        }
    }

    const onExcluirEmpresa = async () => {
        try {
            await api.delete('/emitente/' + selectedId);
            toast.success('Emitente excluido com sucesso');
            setAtualiza('Sim');
        } catch (error) {
            toast.error('Problema na rotina de exclusão: ' + error);
        }
    }

    const fetchData = async () => {
        try {
            const response = await api.get(`/emitentesAll/${acessoContador}/${acesso}`, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            const newToken = response.headers['newtoken']; 
            if (newToken) {
                localStorage.setItem('sessionToken', newToken);
                setTokenSession(newToken);
            }
            // Trabalha com os dados da resposta
            if (response.data) {
                setEmitente(response.data);
            }
        } catch (error) {
            toast.error('Erro ao buscar token, faça login novamente :', error);
            navigate('/login');
        }
    };

    useEffect(() => {
        fetchData();
    }, [atualiza]);

    return (
        <div className='container-emitente'>
            <ModalEmitente statusModal={openModalNova} onCloseModal={handleCloseModalNova} selectedId={selectedId} ></ModalEmitente>
            <ModalDialog statusModal={openModalDialog} onCloseModalDialog={handleCloseModalDialog} statusEmitente={onMudaStatus} excluirEmpresa={onExcluirEmpresa} ativarEmpresa={onAtivarEmpresa} selectedId={selectedId} titulo={titulo} origem='Emitente' operacao={operacao}></ModalDialog>
            <div className="emi-container-top">
                <div className="emi-column-top">
                    <h2 style={{ color: '#fff' }} >Cadastro de Emitente de NFS-e</h2>
                </div>
                <div className="column-top-btn">
                    <button onClick={handleOpenModalNova}>Novo</button>
                </div>
            </div>

            <div className="emitente-meio">
                <Box sx={{ height: 500, width: '100%', overflowX: 'scroll' }}>
                    <DataGrid
                        autoHeight
                        rows={emitente ?? []}
                        columns={columns}
                        disableColumnResize={true}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick
                    />
                </Box>

            </div>

        </div>
    );
}

export default Emitente;