import { useState, useEffect } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import jpIMG from "../../img/jp.svg";
import { LayoutLogin } from '../../components/LayoutLogin/LayoutLogin.jsx'
import "./login.css";
import api from "../../api.js";
import { toast } from "react-toastify";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [contador, setContador] = useState(false);
    const [chave, setChave] = useState("");

    const navegate = useNavigate();

    const token = localStorage.getItem("sessionToken");

    // const [emitentes, setEmitentes] = useState([]);
    // const [selectedEmitente, setSelectedEmitente] = useState('');

    // Função para buscar os emitentes da API
    // const fetchEmitentes = async () => {
    //     try {
    //         const response = await api.get('/emitentesAll/0');
    //         alert(JSON.stringify(response));

    //         const data = await response.json();
    //         setEmitentes(data);
    //     } catch (error) {
    //         console.error('Erro ao buscar emitentes:', error);
    //     }
    // };

    //  useEffect(() => {
    //      (async () => {
    //          if (token) {
    //              try {
    //                  const { data } = await api.post("/refreshToken");
    //                  alert(data.token);
    //                  api.defaults.headers.Authorization = `Bearer ${data.token}`;
    //                  localStorage.setItem("sessionToken", data.token);
    //              } catch (err) {
    //                  toast.error(err);
    //              }
    //          }
    //      })();
    //  }, []);

    // api.interceptors.request.use(
    //     config => {
    //         const token = localStorage.getItem("sessionToken");
    //         if (token) {
    //             config.headers["Authorization"] = `Bearer ${token}`;
    //         }
    //         return config;
    //     },
    //     error => {
    //         Promise.reject(error);
    //     }
    // );

     /*api.interceptors.response.use(
         response => {
             return response;
         },
         async error => {
             const originalRequest = error.config;
             if (error?.response?.status === 401 && !originalRequest._retry) {
                 originalRequest._retry = true;

                 const { data } = await api.post("/refreshToken");
                 if (data) {
                     localStorage.setItem("sessionToken", JSON.stringify(data.token));
                     api.defaults.headers.Authorization = `Bearer ${data.token}`;
                 }
                 return api(originalRequest);
             }
             if (error?.response?.status === 403) {
                 localStorage.removeItem("sessionToken");
                 api.defaults.headers.Authorization = undefined;
             }
             return Promise.reject(error);
         }
     );
*/
    // Exemplo de como implementar no cliente (React)

    // api.interceptors.response.use(response => {
    //     console.log(JSON.stringify(response));    
    //     //const newToken = response.headers.get('New-Token');    
    //     const newToken = response.headers['new-token'];
    //     if (newToken) {
    //         console.log(JSON.stringify(newToken));
    //         localStorage.setItem('sessionToken', newToken);
    //         // Atualiza o token nos headers das próximas requisições porem não está funcionando
    //         api.defaults.headers.Authorization = `Bearer ${newToken}`;
    //       //  api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    //     }
    //     return response;
    // },error => {
    //     console.log(error);
        
    // });

    const FazerLogin = async (e) => {
        e.preventDefault();

        if (!chave) {
            toast.warning('Você deve fornecer sua chave de acesso');
            return;
        }

        if (contador) {
            const chaveAcesso = await api.get('/contadorAcesso/' + chave);
            if (chaveAcesso.data[0].total > 0) {
                localStorage.setItem("sessionContador", chaveAcesso.data[0].id);
                localStorage.setItem("emitenteId", 0);
            } else {
                localStorage.setItem("sessionContador", 0);
                toast.error('Acesso de contador não permitido.');
                return;
            }
        } else {
            localStorage.setItem("sessionContador", 0);
            const valido = await api.get('/emitenteChaveAcesso/' + chave);
            
            if (valido.status === 200) {
                if (valido.data !== "" && valido.data?.length > 0) {
                    localStorage.setItem("sessionChaveAcesso", chave);
                    localStorage.setItem("emitenteId", valido.data[0].id);
                } else {
                    toast.warning('Chave de acesso invalida..');
                    localStorage.setItem("emitenteId", 0);
                    return;
                }
            } else {
                toast.error('Falha ao buscar a chave de acesso, codigo: ' + valido.status);
                return;
            }
        }

        if (email && password) {
            await api.post('/login', { email, password }).then((retorno) => {
                const token = retorno.data.token;
                if (token) {
                    localStorage.setItem("sessionToken", token);
                    localStorage.setItem("usuarioLogado", retorno.data.nome);
                    localStorage.setItem("usuarioId", retorno.data.id);
                    api.defaults.headers.Authorization = `Bearer ${token}`;
                    toast.success("Login efetuado com sucesso...")
                    navegate("/home");
                } else {
                    toast.warning('Dados não conferem...');
                    localStorage.setItem("usuarioId", "");
                }
            }).catch(erro => toast.error('Dados não conferem... ' + erro));
        } else {
            toast.warning('Favor informar usuário e senha...');
        }
    }


    return (
        <LayoutLogin>
            <form className="login-form">
                <span className="login-form-title"> Bem vindo </span>

                <span className="login-form-title">
                    <img src={jpIMG} alt="Jovem Programador" />
                </span>

                <div className="wrap-input">
                    <input
                        className={email !== "" ? "has-val input" : "input"}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="focus-input" data-placeholder="Email"></span>
                </div>

                <div className="wrap-input">
                    <input
                        className={password !== "" ? "has-val input" : "input"}
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <span className="focus-input" data-placeholder="Password"></span>
                </div>

                <div className="wrap-input">
                    <input
                        className={chave !== "" ? "has-val input" : "input"}
                        type="label"
                        value={chave}
                        onChange={(e) => setChave(e.target.value)}
                    />

                    <span className="focus-input" data-placeholder="Chave Acesso"></span>
                </div>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={contador}
                            onChange={(e) => setContador(e.target.checked)}
                            sx={{
                                backgroundColor: '#adadad',
                                color: 'red', // Cor do checkbox
                                '&.Mui-checked': {
                                    color: 'blue', // Cor quando selecionado
                                }
                            }}
                        />
                    }
                    label="Administrador"
                    sx={{
                        backgroundColor: '#adadad',
                        width: '90%',
                        color: 'text.primary', // Cor do texto
                        fontSize: '1rem', // Tamanho da fonte
                        marginLeft: '8px', // Espaçamento à esquerda
                        marginBottom: '20px'
                    }}
                />

                <div className="container-login-form-btn">
                    <button onClick={(e) => FazerLogin(e)} className="login-form-btn">Login</button>
                </div>

                {/* <div className="text-center">
                    <span className="txt1">Não possui conta? </span>
                    <Link className="txt2" to="/register">
                        Criar conta
                    </Link>
                </div> */}
            </form>
        </LayoutLogin>
    );
}

export default Login;