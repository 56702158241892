import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Home from "./pages/Home/home.jsx";
import PriveteRoute from "./components/Private-route/private-route.jsx";
import Login from "./pages/Login/login.jsx";


function Rotas() {
   return <BrowserRouter>
        <Routes>
            {/* <Route path="/home" element={<PriveteRoute><Home /></PriveteRoute>}></Route> */}
            <Route path="/home" element={<Home />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/" element={<Login />}></Route>
        </Routes>
        <ToastContainer autoClose="2000" />
   </BrowserRouter>
}

export default Rotas;