import './home.css';
import FotoUserBlack from '../../img/nfe96.png'
import { useState, useEffect } from 'react';
import Compromissos from '../../components/Compromissos/Compromissos.jsx';
import ChartNfse from './GraficoNfse.jsx';
import ChartContasPagar from './GraficoContasPagar.jsx';
import ChartFaturamento from './GraficoFaturamento.jsx';
import NFSe from '../../components/Nfse/Nfse.jsx';
import Emitente from '../../components/Emitente/Emitente.jsx';
import Clientes from '../../components/Clientes/Clientes.jsx';
import Impostos from '../../components/Impostos/Impostos.jsx';
import Despesas from '../../components/Despesas/Despesa.jsx';
import Servicos from '../../components/Servicos/Servicos.jsx';
import Faturamento from '../../components/Faturamento/Faturamento.jsx';
import Usuarios from '../../components/Usuarios/Usuarios.jsx';
import api from "../../api";
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import ContasPgar from '../../components/ContasPagar/ContasPagar.jsx'

function Home() {
    const navegate = useNavigate();
    const [mostrarDivMeio, setMostrarDivMeio] = useState('dashboard');
    const usuarioLogado = localStorage.getItem("usuarioLogado");
    const [ano, setAno] = useState("2024");
    const [emitentes, setEmitentes] = useState("");
    const [selectedEmitente, setSelectedEmitente] = useState(0);
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    const acesso = localStorage.getItem('sessionChaveAcesso');
    const acessoContador = localStorage.getItem("sessionContador");
    const [totalPagar, setTotalPagar] = useState(0);
    const [totalPagas, setTotalPagas] = useState(0);
    const [totalReceber, setTotalReceber] = useState(0);
    const [totalNfse, setTotalNfse] = useState(0);

    const alternarVisibilidade = (valor) => {
        setMostrarDivMeio(valor);
    };

    function handleReferencia(e) {
        setAno(e.target.value);
    }

    useEffect(() => {
        (async () => {
            try {
                const totpagar = await api.get('/constaPagar/mensal/11/2024/A');
                if (totpagar.status === 200){
                    setTotalPagar(totpagar.data[0].total);
                } else {
                    setTotalPagar(0);
                }
                const totpagas = await api.get('/constaPagar/mensal/11/2024/P');
                if (totpagas.status === 200){
                    setTotalPagas(totpagas.data[0].total);
                } else {
                    setTotalPagas(0);
                }
                const totreceber = await api.get('/faturamento/mensal/11/2024/A');
                if (totreceber.status === 200){
                    setTotalReceber(totreceber.data[0].total);
                } else {
                    setTotalReceber(0);
                }
                const totnfse = await api.get('/nfse/2024-11-01/2024-11-30/Enviada/684');    
                if (totnfse?.status === 200){
                    setTotalNfse(totnfse?.data[0]?.total);
                } else {
                    setTotalNfse(0);
                }
            } catch (error) {
                toast.error('Problema ao totalizar as contas codigo:  ' + error.response.status);
            }
        })();
    }, [ano]);

    useEffect(() => {
        if (!tokenSession) {
            navegate('/login');
        }
    }, []);

    // Select emitentes
    useEffect(() => {
        (async () => {
            try {
                if (tokenSession) {
                    const emitente = await api.get("/emitentesAll/" + acessoContador + "/" + acesso, {
                        headers: {
                            'Authorization': `Bearer ${tokenSession}`,
                        }
                    });
                    const newToken = emitente.headers['newtoken'];
                    if (newToken) {
                        localStorage.setItem('sessionToken', newToken);
                        setTokenSession(newToken);
                    }
                    if (emitente.data) {
                        if (emitente.data.length > 0) {
                            setEmitentes(emitente.data);
                            setSelectedEmitente(emitente.data[0].id);
                        }
                    }
                }
            } catch (error) {
                toast.warning('É necessário fazer login.');
            }
        })();
    }, []);

    return <div className='home-container'>
        <div className="div-esquerda">
            <img src={FotoUserBlack} alt="" className="img-esquerda" />
            <div style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
                <label style={{ color: '#fff' }}>Usuário : {usuarioLogado}</label>
            </div>
            <hr />
            <div className="nav-esquerda">
                <ul id="ul-navbar">
                    <li>
                        <img width="94" height="94" src="https://img.icons8.com/3d-fluency/94/control-panel.png" alt="control-panel" />
                        <a href="#" onClick={() => alternarVisibilidade('dashboard')}>Dashboard</a>
                    </li>
                    <hr />
                    <li>
                        <img width="48" height="48" src="https://img.icons8.com/fluency/48/person-male.png" alt="person-male" />
                        <a href="#" onClick={() => alternarVisibilidade('emitente')} >Emitente</a>
                    </li>
                    <li>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/manager.png" alt="manager" />
                        <a href="#" onClick={() => alternarVisibilidade('clientes')} >Clientes</a>
                    </li>
                    <li>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/manager.png" alt="manager" />
                        <a href="#" onClick={() => alternarVisibilidade('servicos')} >Serviços</a>
                    </li>
                    <li>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/manager.png" alt="manager" />
                        <a href="#" onClick={() => alternarVisibilidade('usuarios')} >Usuários</a>
                    </li>
                    <hr />
                    <li>
                        <img width="80" height="80" src="https://img.icons8.com/papercut/60/000000/document.png" alt="document" />
                        <a href="#" onClick={() => alternarVisibilidade('faturamento')} >Faturamento</a>
                    </li>
                    <li>
                        <img width="80" height="80" src="https://img.icons8.com/papercut/60/000000/document.png" alt="document" />
                        <a href="#" onClick={() => alternarVisibilidade('nfse')} >NFS-e</a>
                    </li>
                    <hr />
                    <li>
                        <img width="64" height="64" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-tax-accounting-flaticons-lineal-color-flat-icons.png" alt="external-tax-accounting-flaticons-lineal-color-flat-icons" />
                        {/* <a href="#" onClick={() => alternarVisibilidade('imposto')} >Impostos</a> */}
                        <a href="#" >Impostos</a>
                    </li>
                    <li>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/price-tag-euro.png" alt="price-tag-euro" />
                        <a href="#" onClick={() => alternarVisibilidade('contaspagar')} >Contas a Pagar</a>
                        {/* <a href="#" >Contas a Pagar</a> */}
                    </li>
                    <hr />
                    <li>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/price-tag-euro.png" alt="price-tag-euro" />
                        {/* <a href="#" onClick={() => alternarVisibilidade('compromissos')} >Compromissos</a> */}
                        <a href="#" >Compromissos</a>
                    </li>
                    <li>
                        <img width="60" height="60" src="https://img.icons8.com/papercut/60/000000/chat.png" alt="chat" />
                        <a href="#" onClick={() => alternarVisibilidade('dashboard')} >Chat</a>
                    </li>
                </ul>
                <br></br>
                <br></br>
                <div className='nav-baixo'>
                    <img width="30" height="30" src="https://img.icons8.com/offices/30/facebook-new.png" alt="facebook-new" />
                    <img width="30" height="30" margin-left="10" src="https://img.icons8.com/cotton/64/whatsapp--v3.png" alt="whatsapp--v3" />
                    <img width="30" height="30" margin-left="10" src="https://img.icons8.com/color/48/email.png" alt="email" />
                </div>
                <br></br>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" endIcon={<LogoutIcon />} size="small" onClick={() => {
                        localStorage.removeItem('sessionToken');
                        localStorage.removeItem('sessionChaveAcesso');
                        localStorage.removeItem('usuarioId');
                        localStorage.removeItem('usuarioLogado');
                        localStorage.removeItem('sessionContador');
                        navegate("/login");
                    }}>
                        Logout
                    </Button>

                </div>
            </div>
        </div>

        {mostrarDivMeio === 'dashboard' && <div id='dashboard' className='div-meio'>
            <div className="div-meio-superior">
                <p className='p-select'>Empresa.: </p>
                <select className='filtrar-cliente' style={{ width: 200, border: 'none' }}
                    onChange={(e) => setSelectedEmitente(e.target.value)}
                    required
                    value={selectedEmitente ? selectedEmitente : ''}
                >
                    <option value="">Selecione um emitente</option>
                    {
                        Array.isArray(emitentes) && emitentes.length > 0 ? (
                            emitentes.map((emit) => (
                                <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                            ))
                        ) : (
                            <option value="">Nenhum emitente disponível</option>
                        )
                    }
                </select>
                <p className='p-select'>Ano ref.: </p>
                <select className='ref-select'
                    onChange={handleReferencia}
                    value={ano}
                >
                    <option key={1} value="2024">2024</option>
                    <option key={2} value="2023">2023</option>
                </select>
                {/* <button className='btn-select'>Filtrar</button> */}
            </div>
            <div className="div-meio-meio-home">
                <div className="nfsex">
                    <ChartFaturamento ano={ano} />
                </div>
                <div className="nfsex">
                    <ChartNfse ano={ano} />
                </div>

                {/* <div className='div-xx'>
                    <button className='btn_meio'>Banco</button>
                    <button className='btn_meio'>Pró-labore</button>
                    <button className='btn_meio'>Boletos</button>
                </div> */}
            </div>
            <div className="div-meio-baixo">
                <div className='div-x'>
                    <ChartContasPagar ano={ano} />
                </div>
                <div className='div-x'>
                    <div style={{ maxWidth: 400, margin: 'auto', textAlign: 'center' }}>
                        <h2 style={{ fontSize: 22, color: '#000', marginBottom: 10 }}>Resumo do mês atual</h2>

                        <div className='div-resumo' style={{ backgroundColor: '#c03b3b' }}>
                            <h3>Total de Contas a pagar: R$ {totalPagar}</h3>
                        </div>

                        <div className='div-resumo'>
                            <h3>Total de Contas pagas: R$ {totalPagas}</h3>
                        </div>

                        <div className='div-resumo'>
                            <h3>Total de Contas a receber: R$ {totalReceber}</h3>
                        </div>

                        <div className='div-resumo'>
                            <h3>Total de NFSe emitidas: R$ {totalNfse}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }

        {mostrarDivMeio === 'faturamento' && <div className='div-meio'>
            <Faturamento />
        </div>
        }

        {mostrarDivMeio === 'nfse' && <div className='div-meio'>
            <NFSe />
        </div>
        }

        {mostrarDivMeio === 'emitente' && <div className='div-meio'>
            <Emitente />
        </div>
        }

        {mostrarDivMeio === 'usuarios' && <div className='div-meio'>
            <Usuarios />
        </div>
        }

        {mostrarDivMeio === 'clientes' && <div className='div-meio'>
            <Clientes />
        </div>
        }

        {mostrarDivMeio === 'imposto' && <div className='div-meio'>
            <Impostos />
        </div>
        }

        {mostrarDivMeio === 'despesa' && <div className='div-meio'>
            <Despesas />
        </div>
        }

        {mostrarDivMeio === 'servicos' && <div className='div-meio'>
            <Servicos />
        </div>
        }

        {mostrarDivMeio === 'compromissos' && <div className='div-meio'>
            <Compromissos />
        </div>
        }

        {mostrarDivMeio === 'contaspagar' && <div className='div-meio'>
            <ContasPgar />
        </div>
        }

    </div>
}

export default Home;