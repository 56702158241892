import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem } from "@mui/material";
import './Modal-dialog.css';
import api from "../../api.js";

function ModalConfirmacao({ statusModal, onCloseModalConfirmacao, selectedId, titulo, onEnviarNFSe, onBaixaPagar, onExcluirPagar, onExcluir, onExcluirNFSe, onRepetirNFSe, origem, operacao, onExcluirFaturamento, onCancelarNFSe, onBaixaFaturamento, selectedRows, onEnviarEmail }) {

    const handleClose = () => {
        onCloseModalConfirmacao();
    }

    const handleExcluir = () => {
        onExcluir(selectedId);
        onCloseModalConfirmacao();
    }

    const handleExcluirPagar = () => {
        onExcluirPagar(selectedId);
        onCloseModalConfirmacao();
    }

    const handleExcluirNFSe = () => {
        onExcluirNFSe(selectedId);
        onCloseModalConfirmacao();
    }

    const handleRepetirNFSe = () => {
        onRepetirNFSe(selectedId);
        onCloseModalConfirmacao();
    }

    const handleCancelarNFSe = () => {
        onCancelarNFSe(selectedId);
        onCloseModalConfirmacao();
    }

    const handleExcluirFaturamento = () => {
        onExcluirFaturamento(selectedId);
        onCloseModalConfirmacao();
    }

    const handleBaixarFaturamento = async () => {
        selectedRows.map(async (obj) => {
            await api.put('/faturamentoBaixa/' + obj.id);            
        })
        onBaixaFaturamento();
        handleClose();
    }

    const handleEnviarEmail = () => {
        onEnviarEmail(selectedId);
    }    

    const handleEnviar = () => {
        onEnviarNFSe();
    }

    const handleBaixarPagar = async () => {        
        selectedRows.map(async (obj) => {
            await api.put('/contasPagar/baixa/' + obj.id);            
        })
        onBaixaPagar()
        handleClose();
    }    

    return (
        <div>
            <Dialog className="d-container" open={statusModal} onClose={handleClose} scroll="paper" fullWidth maxWidth="lg">
                <DialogTitle>{titulo}</DialogTitle>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Sair</button>
                    {origem === 'Clientes' && (
                        <button className="btn-incluir" onClick={handleExcluir}>Excluir</button>
                    )}
                    {origem === 'NFSe' && operacao === 'Excluir' && (
                        <button className="btn-incluir" onClick={handleExcluirNFSe}>Excluir</button>
                    )}                  
                    {origem === 'NFSe' && operacao === 'Repetir' && (
                        <button className="btn-incluir" onClick={handleRepetirNFSe}>Repetir</button>
                    )}  
                    {origem === 'NFSe' && operacao === 'Email' && (
                        <button className="btn-incluir" onClick={handleEnviarEmail}>Enviar</button>
                    )}  
                    {origem === 'NFSe' && operacao === 'send' && (
                        <button className="btn-incluir" onClick={handleEnviar}>Enviar</button>
                    )}  
                    {origem === 'NFSe' && operacao === 'Cancelar' && (
                        <button className="btn-incluir" onClick={handleCancelarNFSe}>Cancelar</button>
                    )}                     
                    {origem === 'Faturamento' && operacao === 'Excluir' && (
                        <button className="btn-incluir" onClick={handleExcluirFaturamento}>Excluir</button>
                    )}  
                    {origem === 'Faturamento' && operacao === 'Baixa' && (
                        <button className="btn-incluir" onClick={handleBaixarFaturamento}>Baixar</button>
                    )}                
                    {origem === 'Pagar' && operacao === 'Baixar' && (
                        <button className="btn-incluir" onClick={handleBaixarPagar}>Baixar</button>
                    )}                
                    {origem === 'Pagar' && operacao === 'Excluir' && (
                        <button className="btn-incluir" onClick={handleExcluirPagar}>Excluir</button>
                    )}                
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ModalConfirmacao;