
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { toast } from 'react-toastify';
// import './Modal-faturamento.css';
import { useEffect, useState } from "react";
import api from "../../api";

function ModalPagar({ statusModal, onCloseModal, operacao, selectedId, emitentes }) {

    const [selectedEmitente, setSelectedEmitente] = useState(0);
    const [selectedCredor, setSelectedCredor] = useState(0);
    const [descricao, setDescricao] = useState("");
    const [valor, setValor] = useState(0);
    const [vencimento, setVencimento] = useState("");
    const [boleto, setBoleto] = useState("");
    const [nfse, setNfse] = useState("");
    const [credores, setCredores] = useState([]);
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    const acessoContador = localStorage.getItem("sessionContador");

    // credores
    useEffect(() => {
        if (!selectedEmitente) { return;}
        (async () => {
            try {
                const { data } = await api.get("/credores/" + selectedEmitente);
                if (data.success) {
                    setCredores(data.data);
                    if (data.data.length > 0) {
                        setSelectedCredor(data.data[0].id);
                    }
                }
            } catch (error) {
                toast.error(error);
            }
        })();
    }, [selectedEmitente]);

    useEffect(() => {
        (async () => {
            try {
                if (!selectedId) {
                    // nesse caso é uma conta nova
                    return;
                }
                const data = await api.get("/contasPagarId/" + selectedId, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (!data.data) return;
                const newToken = data.headers['newtoken'];
                if (newToken) {
                    localStorage.setItem('sessionToken', newToken);
                    setTokenSession(newToken);
                }
                if (data.data[0]) {
                    const obj = (data.data[0]);
                    const { id_emitente, id_credor, descricao, valor, vencimento } = obj;
                    setValor(valor);
                    setDescricao(descricao);
                    setSelectedEmitente(id_emitente === 0 ? 0 : id_emitente);
                    setSelectedCredor(id_credor === 0 ? 0 : id_credor);
                    const dt = new Date(vencimento);
                    // Obtenha o dia, mês e ano
                    const dia = String(dt.getUTCDate()).padStart(2, '0');
                    const mes = String(dt.getUTCMonth() + 1).padStart(2, '0'); // Janeiro é 0, então adicionamos 1
                    const ano = dt.getUTCFullYear();
                    // Formate a data no estilo 'YYYY-MM-DD'
                    const dataFormatada = `${ano}-${mes}-${dia}`;
                    setVencimento(dataFormatada);
                }
            } catch (e) {
                toast.error("Problema na rotina: ", e);
            }
        })();
    }, [selectedId]);


    const handleClose = () => {
        setValor(0);
        setDescricao("");
        setSelectedCredor(0);
        setSelectedEmitente(0);
        setVencimento("");
        onCloseModal();
    }

    const handleUpdate = async (id) => {
        try {
            if (selectedCredor && selectedEmitente && valor > 0 && descricao != '' && vencimento != '') {
                const obj = {
                    "emitente": selectedEmitente,
                    "credor": selectedCredor,
                    "descricao": descricao,
                    "valor": valor,
                    "vencimento": vencimento
                };
                await api.put('/contasPagar/update/' + selectedId, obj,
                    {
                        headers: {
                            'Authorization': `Bearer ${tokenSession}`,
                        }
                    });
                toast.success('Update efeturado com sucesso');
                handleClose();
            } else {
                toast.warning('Favor preencher todos os dados.');
            }

        } catch (error) {
            toast.warning('Favor informar todos os campos.');
        }
    }

    const handleInserir = async () => {
        try {
            if (selectedCredor && selectedEmitente && valor > 0 && descricao != '' && vencimento != '') {
                const obj = {
                    "emitente": selectedEmitente,
                    "credor": selectedCredor,
                    "descricao": descricao,
                    "valor": valor,
                    "vencimento": vencimento
                };
                await api.post('/contasPagar/nova', obj, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                toast.success('conta incluida com sucesso.');
                handleClose();
            } else {
                toast.warning('Favor informar todos os campos.');
            }
        } catch (error) {

        }
    }

    const handleEmitente = async (emit) => {
        setSelectedEmitente(emit);        
    }

    return (
        <div>
            <Dialog open={statusModal} onClose={handleClose} scroll="paper" fullWidth>
                <DialogTitle>Controle de contas a pagar</DialogTitle>
                <DialogContent dividers>
                    <label>Emitente : </label>
                    <select className='select-item'
                        onChange={(e) => handleEmitente(e.target.value)}
                        required
                        value={selectedEmitente}
                    >
                        <option key='99999' value='99999'>Escolha um Emitente</option>
                        {
                            emitentes.map((emit) => (
                                <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                            ))
                        }
                    </select>
                </DialogContent>
                <hr></hr>
                <DialogContent dividers>
                    <label>Credor : </label>
                    <select className='select-item'
                        onChange={(e) => setSelectedCredor(e.target.value)}
                        required
                        value={selectedCredor}
                    >
                        <option key='99999' value='99999'>Escolha um Credor</option>
                        {
                            credores.map((cre) => (
                                <option key={cre.id} value={cre.id}>{cre.nome}</option>
                            ))
                        }
                    </select>
                </DialogContent>
                <hr></hr>
                <DialogContent dividers>
                    <TextField
                        fullWidth
                        required
                        value={descricao}
                        id="descricao"
                        label="Descrição"
                        variant="filled"
                        onChange={(e) => setDescricao(e.target.value)}
                    />
                </DialogContent>
                <hr></hr>
                <DialogContent dividers>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            required
                            type="number"
                            value={valor}
                            id="valor"
                            label="Valor"
                            variant="filled"
                            onChange={(e) => setValor(e.target.value)}
                        />
                        <TextField
                            required
                            type="date"
                            value={vencimento}
                            id="vencimento"
                            label="Vencimento"
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setVencimento(e.target.value)}
                        />
                    </div>
                </DialogContent>
                <hr></hr>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Fechar</button>
                    {operacao === 'Editar' && (
                        <button className="btn-incluir" onClick={handleUpdate}>Alterar</button>
                    )}
                    {operacao === 'Incluir' && (
                        <button className="btn-incluir" onClick={handleInserir}>Salvar</button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModalPagar;