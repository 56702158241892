import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem } from "@mui/material";
import { toast } from 'react-toastify';
import './Modal-cliente.css';
import { useEffect, useState } from "react";
import api from "../../api";
import { QueryStats } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';

function ModalCliente({ statusModal, onCloseModal, selectedId }) {

    const [cnpj, setCnpj] = useState("");
    const [ie, setIe] = useState("");
    // const [icm, setIcm] = useState("");
    const [razao, setRazao] = useState("");
    const [nome, setNome] = useState("");
    const [endereco, setEndereco] = useState("");
    const [bairro, setBairro] = useState("");
    const [numero, setNumero] = useState("");
    const [selectedUf, setSelectedUf] = useState("");
    const [selectedNFSe, setSelectedNFSe] = useState("");
    const [selectedBoleto, setSelectedBoleto] = useState("");
    const [selectedCidade, setSelectedCidade] = useState("");
    const [cep, setCep] = useState("");
    const [ibge, setIbge] = useState("");
    const [ufs, setUfs] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [cpf, setCpf] = useState("");
    const [rg, setRg] = useState("");
    const [email1, setEmail1] = useState("");
    const [email2, setEmail2] = useState("");
    const [email3, setEmail3] = useState("");
    const [valorServico, setValorServico] = useState(0);
    const [emitentes, setEmitentes] = useState("");
    const [selectedEmitente, setSelectedEmitente] = useState("");
    const acesso = localStorage.getItem('sessionChaveAcesso');
    const acessoContador = localStorage.getItem("sessionContador");
    const [editando, setEditando] = useState("");
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));
    
    const estadosArray = {
        "AC": 12,
        "AL": 27,
        "AM": 13,
        "AP": 16,
        "BA": 29,
        "CE": 23,
        "DF": 53,
        "ES": 32,
        "GO": 52,
        "MA": 21,
        "MG": 31,
        "MS": 50,
        "MT": 51,
        "PA": 15,
        "PB": 25,
        "PE": 26,
        "PI": 22,
        "PR": 41,
        "RJ": 33,
        "RN": 24,
        "RO": 11,
        "RR": 14,
        "RS": 43,
        "SC": 42,
        "SE": 28,
        "SP": 35,
        "TO": 17
    };

    // Select emitentes
    const fetchEmitentes = async () => {
        try {
            const response = await api.get(`/emitentesAll/${acessoContador}/${acesso}`, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            const newToken = response.headers['newtoken']; 
            if (newToken) {
                localStorage.setItem('sessionToken', newToken);  
                setTokenSession(newToken);              
            }
            // Trabalha com os dados da resposta
            if (response.data) {
                setEmitentes(response.data);
            }
        } catch (error) {
            toast.error('Erro ao buscar token, faça login novamente :', error);
        }
    };
    useEffect(() => {
        fetchEmitentes()
    }, []);

    // estados do banco
    useEffect(() => {
        (async () => {
            try {
                const { data: estados } = await api.get("/estados");
                if (estados) {
                    setUfs(estados);
                }
            } catch (error) {
                alert(error);
            }
        })();
    }, [setUfs]);

    useEffect(() => {
        (async () => {
            try {
                if (!selectedId) {
                    setEditando("N");
                    return;
                }
                const  data  = await api.get("/cliente/" + selectedId, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (!data.data || data.data.length <= 0) return;
                const newToken = data.headers['newToken']; 
                if (newToken) {
                    localStorage.setItem('sessionToken', newToken);
                    setTokenSession(newToken);
                }
                if (data.data[0]) {
                    const obj = (data.data[0]);
                    const { razao, cnpj, cep, ibge, ie, nome, endereco, bairro, id_cidade, uf, numero, cpf, rg, email1, email2, email3, gerar_nfse, valor_servico, gerar_boleto, id_emitente } = obj;
                    setRazao(razao);
                    setCnpj(cnpj);
                    setCep(cep);
                    setIbge(ibge);
                    setIe(ie);
                    setEndereco(endereco);
                    setNome(nome);
                    setBairro(bairro);
                    handleEstado(uf);
                    setSelectedNFSe(gerar_nfse);
                    setSelectedBoleto(gerar_boleto);
                    setTimeout(() => {
                        setSelectedCidade(id_cidade === 0 ? "" : id_cidade);
                    }, 500);
                    setNumero(numero);
                    setCpf(cpf);
                    setRg(rg);
                    setEmail1(email1);
                    setEmail2(email2);
                    setEmail3(email3);
                    setSelectedEmitente(id_emitente === 0 ? "" : id_emitente);
                    setValorServico(valor_servico);
                    setEditando("S");
                }
            } catch (e) {
                toast.error("Problema na rotina: ", e);
            }
        })();
    }, [selectedId]);

    const handleClose = () => {
        setRazao("");
        setCnpj("");
        setCep("");
        setIbge("");
        setIe("");
        setEndereco("");
        setNome("");
        setBairro("");
        setSelectedUf("");
        setSelectedCidade("");
        setNumero("");
        setCpf("");
        setRg("");
        setEmail1("");
        setEmail2("");
        setEmail3("");
        setSelectedNFSe("");
        setValorServico(0);
        onCloseModal();
        setSelectedBoleto("");
        setSelectedEmitente("");
    }

    const handleInserir = async () => {
        if ((!cnpj) && (!cpf)) {
            toast.error("CNPJ/CPF deve ser preenchido")
            return;
        }
        if (cnpj){
            const jaCadastrado = await api.get('/clienteCnpj/' + cnpj.replace(/\D/g, ''));
            if (jaCadastrado?.data[0]?.id > 0) {
                toast.warning('Esse cnpj já está cadastrado para outro cliente..');
                return;
            }
        }
        if (!nome) {
            toast.error("Nome deve ser preenchido")
            return;
        }
        if (!razao) {
            toast.error("Razão Social deve ser preenchida")
            return;
        }
        if (!selectedUf) {
            toast.error("Selecione um estado válido")
            return;
        }
        if (!selectedCidade) {
            toast.error("Selecione uma cidade válida")
            return;
        }
        if (!cep) {
            toast.error("Selecione um CEP válido")
            return;
        }
        if (!ibge) {
            toast.error("Selecione um IBGE válido")
            return;
        }
        if (!email1) {
            toast.error("Email financeiro deve ser preenchido");
            return;
        }
        if (!email2) {
            toast.error("Email fiscal deve ser preenchido");
            return;
        }
        if (!selectedEmitente) {
            toast.error("Emitente deve ser preenchido");
            return;
        }
        try {
            const obj =
            {
                nome: nome,
                razao: razao,
                cnpj: cnpj,
                cpf: cpf,
                rg: rg,
                cep: cep,
                ibge: ibge,
                email1: email1,
                email2: email2,
                email3: email3,
                ie: ie,
                endereco: endereco,
                bairro: bairro,
                numero: numero,
                uf: selectedUf,
                id_cidade: selectedCidade,
                gerar_nfse: selectedNFSe,
                valor_servico: valorServico,
                gerar_boleto: selectedBoleto,
                id_emitente: selectedEmitente
            }
            const response = await api.post("/createCliente", obj,{
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            toast.success('Dados inseridos com sucesso:', response.data);
            handleClose();
        } catch (error) {
            toast.error('Erro ao incluir os dados:', error);
        }
    }

    const handleUpdate = async () => {
        if (!nome) {
            toast.error("Nome deve ser preenchido")
            return;
        }
        if (!razao) {
            toast.error("Razão Social deve ser preenchida")
            return;
        }
        if ((!cnpj) && (!cpf)) {
            toast.error("CNPJ/CPF deve ser preenchido")
            return;
        }
        if (!selectedUf) {
            toast.error("Selecione um estado válido")
            return;
        }
        if (!selectedCidade) {
            toast.error("Selecione uma cidade válida")
            return;
        }
        if (!cep) {
            toast.error("Selecione um CEP válido")
            return;
        }
        if (!ibge) {
            toast.error("Selecione um IBGE válido")
            return;
        }
        if (!email1) {
            toast.error("Email financeiro deve ser preenchido");
            return;
        }
        if (!email2) {
            toast.error("Email fiscal deve ser preenchido");
            return;
        }
        if (!selectedEmitente) {
            toast.error("Emitente deve ser preenchido");
            return;
        }
        try {
            const obj =
            {
                nome: nome,
                razao: razao,
                cnpj: cnpj,
                cep: cep,
                ibge: ibge,
                email1: email1,
                email2: email2,
                email3: email3,
                cpf: cpf,
                rg: rg,
                ie: ie,
                endereco: endereco,
                bairro: bairro,
                numero: numero,
                uf: selectedUf,
                id_cidade: selectedCidade,
                gerar_nfse: selectedNFSe,
                valor_servico: valorServico,
                gerar_boleto: selectedBoleto,
                id_emitente: selectedEmitente
            }
            const response = await api.put("/cliente/" + selectedId, obj,{
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            toast.success('Dados atualizados com sucesso:', response.data);
            handleClose();
        } catch (error) {
            toast.error('Erro ao atualizar os dados:', error);
        }
    };

    const handleEstado = async (value) => {
        setSelectedUf(value);
        setSelectedCidade("");
        const { data: cidades } = await api.get("/cidades/" + value);
        if (cidades) {
            if (cidades.length > 0) {
                setCidades(cidades);
            }
        }
    }

    const handleCidade = (idCid) => {
        setSelectedCidade(idCid);
        const dCid = cidades.filter((cid) => cid.id === idCid);
        setIbge(dCid[0]?.codigo_ibge);
    }

    function getCodigoEstado(sigla) {
        return estadosArray[sigla.toUpperCase()] || null; // Retorna o código ou null se a sigla não for encontrada
    }

    const consultaCnpj = async () => {
        try {
            // URL da API da ReceitaWS
            if (!cnpj) {
                alert('Favor informar o cnpj');
            } else {
                const retorno = await api.get('/consultaCnpj/' + cnpj.replace(/\D/g, '')); // somente numeros
                if (retorno !== '') {
                    setNome(retorno.data.fantasia);
                    setRazao(retorno.data.nome);
                    setEndereco(retorno.data.logradouro);
                    setNumero(retorno.data.numero);
                    setBairro(retorno.data.bairro);
                    setCep(retorno.data.cep);
                    handleEstado(getCodigoEstado(retorno.data.uf));
                    const cid = await api.get('/retornaCidade/' + retorno.data.municipio);
                    if (cid !== '') {
                        handleCidade(cid.data.id);
                    }
                    const cep = await api.get('/consultaCep/' + retorno.data.cep);
                    if (cep) {
                        setIbge(cep.data.ibge);
                    }
                }
            }
        } catch (error) {
            console.error('Erro ao buscar dados:', error.response ? error.response.data : error.message);
        }
    }

    return (
        <div>
            <Dialog className="dxcli-container" open={statusModal} onClose={handleClose} scroll="paper" fullWidth maxWidth="lg">
                <DialogTitle>Cliente</DialogTitle>
                <DialogContent dividers>
                    <label>Emitente : </label>
                    <select className='select-item'
                        onChange={(e) => setSelectedEmitente(e.target.value)}
                        required
                        value={selectedEmitente ? selectedEmitente : ''}
                    >
                        <option key='99999' value='99999'>Escolha um Emitente</option>
                        {
                            Array.isArray(emitentes) && emitentes.length > 0 ? (
                                emitentes.map((emit) => (
                                    <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                                ))
                            ) : (
                                <option value="">Nenhum emitente disponível</option>
                            )
                        }
                    </select>
                </DialogContent>
                <DialogContent className="cli-container" dividers>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>

                        <InputMask
                            mask="99.999.999/9999-99"
                            value={cnpj}
                            onChange={(e) => setCnpj(e.target.value)}
                            disabled={editando !== 'N'} // Desabilita se editando for diferente de 'N'
                        >
                            {() => (
                                <TextField
                                    id="cnpj"
                                    label="CNPJ"
                                    variant="standard"
                                    margin="dense"
                                    type="text"
                                    disabled={editando !== 'N'} // Desabilita o TextField se estiver editando
                                />
                            )}
                        </InputMask>
                        <button style={{ height: 35, width: 50, margin: 10, border: 0, cursor: 'pointer' }} onClick={consultaCnpj} > <QueryStats /></button>
                        <TextField
                            id="ie"
                            label="Incr Estadual"
                            variant="standard"
                            margin="dense"
                            value={ie}
                            onChange={(e) => setIe(e.target.value)}
                        />
                        <TextField
                            id="cpf"
                            label="CPF"
                            variant="standard"
                            margin="dense"
                            value={cpf}
                            onChange={(e) => setCpf(e.target.value)}
                        />
                        <TextField
                            id="rg"
                            label="RG"
                            variant="standard"
                            margin="dense"
                            value={rg}
                            onChange={(e) => setRg(e.target.value)}
                        />

                    </div>
                    <TextField
                        required
                        id="razao"
                        label="Razão"
                        margin="dense"
                        variant="standard"
                        fullWidth
                        value={razao}
                        onChange={(e) => setRazao(e.target.value)}
                    />
                    <TextField
                        required
                        id="fantasia"
                        label="Nome Fantasia"
                        margin="dense"
                        variant="standard"
                        fullWidth
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                        <TextField
                            required
                            id="cep"
                            label="CEP"
                            margin="dense"
                            variant="standard"
                            // variant="filled"
                            value={cep}
                            onChange={(e) => setCep(e.target.value)}
                        />
                        <TextField
                            required
                            id="endereco"
                            label="Endereço"
                            margin="dense"
                            variant="standard"
                            // variant="filled"
                            fullWidth
                            value={endereco}
                            onChange={(e) => setEndereco(e.target.value)}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                        <TextField
                            required
                            id="bairro"
                            label="Bairro"
                            variant="standard"
                            // variant="filled"
                            margin="dense"
                            value={bairro}
                            onChange={(e) => setBairro(e.target.value)}
                        />
                        <TextField
                            required
                            maxWidth="50"
                            id="numero"
                            label="Numero"
                            variant="standard"
                            // variant="filled"
                            margin="dense"
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)}
                        />
                        <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedUf ? selectedUf : ''}
                            variant="standard"
                            label="Estado"
                            onChange={(e) => handleEstado(e.target.value)}
                        >
                            <MenuItem key={"nostate"} value="">Selecione um estado</MenuItem>
                            {ufs && ufs.length > 0 && ufs.map((estado) =>
                                <MenuItem key={estado.codigo_uf} value={estado.codigo_uf}>{estado.nome}</MenuItem>
                            )}
                        </Select>
                        <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedCidade}
                            variant="standard"
                            label="Estado"
                            onChange={(e) => handleCidade(e.target.value)}
                        >
                            <MenuItem key={"nostate"} value="">Selecione uma cidade</MenuItem>
                            {cidades && cidades.length > 0 && cidades.map((cid) =>
                                <MenuItem key={cid.codigo_ibge} value={cid.id}>{cid.nome}</MenuItem>
                            )}
                        </Select>
                        <TextField
                            required
                            id="ibge"
                            label="IBGE"
                            // variant="filled"
                            variant="standard"
                            margin="dense"
                            value={ibge}
                            onChange={(e) => setIbge(e.target.value)}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "5px" }}>
                        <TextField
                            required
                            id="email1"
                            label="Email Financeiro"
                            // variant="filled"
                            variant="standard"
                            margin="dense"
                            value={email1}
                            onChange={(e) => setEmail1(e.target.value)}
                        />
                        <TextField
                            required
                            id="email2"
                            label="Email Fiscal"
                            variant="standard"
                            // variant="filled"
                            margin="dense"
                            value={email2}
                            onChange={(e) => setEmail2(e.target.value)}
                        />
                        <TextField
                            id="email3"
                            label="Email Pessoal"
                            variant="standard"
                            // variant="filled"
                            margin="dense"
                            value={email3}
                            onChange={(e) => setEmail3(e.target.value)}
                        />
                    </div>
                    <div>
                        <br></br>
                        <hr style={{ borderTop: '6px double #8c8b8b' }} />
                        <br></br>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "5px" }}>
                        <InputLabel id="demo-select-small-label">Emitir NFSe</InputLabel>
                        <Select
                            labelId="gerarnfse"
                            id="gerarnfse"
                            value={selectedNFSe}
                            variant="filled"
                            label="NFSe"
                            onChange={(e) => setSelectedNFSe(e.target.value)}
                        >
                            <MenuItem key={"nostate"} value="">Emitir NFSe</MenuItem>
                            <MenuItem key="S" value="S">Sim</MenuItem>
                            <MenuItem key="N" value="N">Não</MenuItem>
                        </Select>
                        <InputLabel id="demo-select-small-label">Emitir Boleto</InputLabel>
                        <Select
                            labelId="gerarboleto"
                            id="gerarboleto"
                            value={selectedBoleto}
                            variant="filled"
                            label="Boleto"
                            onChange={(e) => setSelectedBoleto(e.target.value)}
                        >
                            <MenuItem key={"noboleto"} value="">Emitir Boleto</MenuItem>
                            <MenuItem key="S" value="S">Sim</MenuItem>
                            <MenuItem key="N" value="N">Não</MenuItem>
                        </Select>
                        {/* <TextField
                            id="diavencimento"
                            label="Dia Vencto"
                            margin="dense"
                            type="number"
                            variant="filled"
                            value={vencimento}
                            onChange={(e) => setVencimento(e.target.value)}
                        />
                        <TextField
                            id="servico"
                            label="Valor Serviço"
                            margin="dense"
                            variant="filled"
                            value={valorServico}
                            onChange={(e) => setValorServico(e.target.value)}
                        /> */}
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Fechar</button>
                    <button className="btn-incluir" onClick={selectedId ? handleUpdate : handleInserir}>Salvar</button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ModalCliente;