import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem } from "@mui/material";
import { toast } from 'react-toastify';
import './Modal-servico.css';
import { useEffect, useState } from "react";
import api from "../../api";

function ModalServico({ statusModal, onCloseModal, selectedId }) {

    const [codigo, setCodigo] = useState("");
    const [descricao, setDescricao] = useState("");
    const [issretido, setIssRetido] = useState("");
    const [aliquota, setAliquota] = useState(0);

    useEffect(() => {
        (async () => {
            try {
                if (!selectedId) return;
                const { data } = await api.get("/servico/" + selectedId);
                if (!data) return;
                if (data[0]) {
                    const obj = (data[0]);
                    const { codigo, descricao, iss_retido, aliquota } = obj;
                    setCodigo(codigo);
                    setDescricao(descricao);
                    setIssRetido(iss_retido);
                    setAliquota(aliquota);
                }
            } catch (e) {
                toast.error("Problema na rotina: ", e);
            }
        })();
    }, [selectedId]);

    const handleClose = () => {
        setCodigo("");
        setDescricao("");
        setIssRetido("");
        setAliquota(0);
        onCloseModal();
    }

    const handleInserir = async () => {
        if (!descricao) {
            toast.error("Descrição deve ser preenchida")
            return;
        }
        if (!codigo) {
            toast.error("Codigo deve ser preenchido")
            return;
        }
        try {
            const obj =
            {
                codigo: codigo,
                descricao: descricao,
                iss_retido: issretido,
                aliquota: aliquota
            }
            const response = await api.post("/createServico", obj);
            toast.success('Dados inseridos com sucesso:', response.data);
            handleClose();
        } catch (error) {
            toast.error('Erro ao incluir os dados:', error);
        }
    }

    const handleUpdate = async () => {
        if (!descricao) {
            toast.error("Descrição deve ser preenchida")
            return;
        }
        if (!codigo) {
            toast.error("Codigo deve ser preenchido")
            return;
        }
        try {
            const obj =
            {
                codigo: codigo,
                descricao: descricao,
                iss_retido: issretido,
                aliquota: aliquota
            }
            const response = await api.put("/servico/" + selectedId, obj);
            toast.success('Dados atualizados com sucesso:', response.data);
            handleClose();
        } catch (error) {
            toast.error('Erro ao atualizar os dados:', error);
        }
    };

    return (
        <div>
            <Dialog className="d-container" open={statusModal} onClose={handleClose} scroll="paper" fullWidth maxWidth="lg">
                <DialogTitle>Editar Serviço</DialogTitle>
                <DialogContent className="dl-container" dividers>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                        <TextField
                            id="codigo"
                            label="Codigo"
                            variant="standard"
                            margin="dense"
                            value={codigo}
                            onChange={(e) => setCodigo(e.target.value)}
                        />
                        <TextField
                            id="iss"
                            label="ISS Retido"
                            variant="standard"
                            margin="dense"
                            value={issretido}
                            onChange={(e) => setIssRetido(e.target.value)}
                        />
                        <TextField
                            id="aliquota"
                            label="Aliquota"
                            variant="standard"
                            margin="dense"
                            value={aliquota}
                            onChange={(e) => setAliquota(e.target.value)}
                        />
                    </div>
                    <TextField
                        required
                        id="descricao"
                        label="Descrição"
                        margin="dense"
                        variant="standard"
                        fullWidth
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Fechar</button>
                    <button className="btn-incluir" onClick={selectedId ? handleUpdate : handleInserir}>Salvar</button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ModalServico;